import React, { useEffect, useState } from 'react';

const MonthYearSelect = ({setDate}) => {
  // Масив місяців
  const [checkYear, setCheckYear] = useState(false);
  const [checkMonth, setCheckMonth] = useState(false);
  
  const months = [
    'Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 
    'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'
  ];

  // Поточний рік
  const currentYear = new Date().getFullYear();

  // Масив з останніми 10 роками
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

  useEffect(() => {
    setDate({year: checkYear, month: checkMonth});
  },[checkMonth, checkYear])

  return (
    <div>
      {/* <label htmlFor="month">Місяць:</label> */}
      <select id="month" onChange={ (e) => {setCheckMonth(e.target.value)}}>
        <option key={0} value="all">- Всі - </option>
        {months.map((month, index) => {
          const monthNumber = String(index + 1).padStart(2, '0'); // Додаємо 0 перед номером
          return (
            <option key={index} value={monthNumber}>
              {month}
            </option>
          );
        })}
      </select>

      {/* <label htmlFor="year">Рік:</label> */}
      <select id="year" onChange={ (e) => {setCheckYear(e.target.value)}}>
        <option key={0} value="all">- Всі - </option>
        {years.map((year, index) => (
          <option key={index} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MonthYearSelect;
