import Card from 'react-bootstrap/Card';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormSelect } from '@coreui/react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";
import { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Message from 'src/components/docs/Message';
import HistoryPower from 'src/components/docs/HistoryPower';
import { FaFilePdf, FaRegFileAlt } from "react-icons/fa";








const urlRouter = '/agreements';
const urlApi = '/agreements';

export async function LoaderAgreementsView({ params }) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
        return response;
    });
    return res;
};


const AgreementsView = ({user_data}) => {
    const { data } = useLoaderData();
    const [permissionEdit, SetPermissionEdit] = useState((Permissions(user_data, 'Accounts', 'EDIT_AGREEMNTS'))?true:false) ;
    const [docs, setDocs] = useState([]);

    const [scan, setScan] = useState(false);
    const [listEis, setListEis] = useState(false);
    const [score, setScore] = useState(false);

    const getItemsAgreements = (type) => {
        
        AxiosInstance.get(`/documents`, { params: {'agreement.id': data.id, type: type} }).then((response) => {
            // console.log('/page_infos get response', response);
            if (response.status == 200){
                if(type == 'скан договору') setScan(response.data['hydra:member']);
                if(type == 'додаткова угода') setScore(response.data['hydra:member']);
                if(type == 'список ЕІС') setListEis(response.data['hydra:member']);
            }
        });
    };

    useEffect(() => {
        getItemsAgreements('скан договору');
        getItemsAgreements('додаткова угода');
        getItemsAgreements('список ЕІС');
    }, []);
    useEffect(() => {
        if(scan !== false && score !== false && listEis){
            // console.log(data);
            const mergedArray = [...scan, ...score, ...listEis];

            // Сортуємо по id
            mergedArray.sort((a, b) => a.id - b.id);

            setDocs(mergedArray);
        }        
    }, [scan, score, listEis]);

    return (<>
 
        <Card>
            <Card.Header>
                <div className="row">
                    <div className="col-10">
                        <h2>Договір: {data.name} {data.number}</h2>
                    </div>
                    
                    <div className="col-2 d-flex justify-content-end"> 
                        {(permissionEdit) &&
                            <Link to={`${urlRouter}/update/${data.id}`}><CButton color="dark" variant="outline" title="Редагувати контрагента">Редагувати <CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>
                        }
                    </div>
                </div>

            </Card.Header>
            <Card.Body>
                <Table striped>
                    <tbody>
                        {(data.type != undefined) && <tr> <th>Номер</th> <td>{data.number}</td> </tr> }
                        {( data.dateEnd != undefined) && <tr> <th>Договір діє до</th> <td>{data.dateEnd}</td> </tr> }
                        {( data.iban != undefined) && <tr> <th>IBAN</th> <td>{data.iban}</td> </tr> }
                        {( data.description != undefined) && <tr> <th>Коментар</th> <td>{data.description}</td> </tr> }
                        {( data.account != undefined) && <tr> <th>Контрагент</th> <td>{data.account.name}</td> </tr> }
                    </tbody>
                </Table>
                
            </Card.Body>
        </Card>
        <hr/>

        <Card>
            <Card.Header as="h5">Документи</Card.Header>
            <Card.Body className='row'>
                {(docs.length == 0 )&& <p>Документів не знайдено!</p>}
                <Card >
                    <ListGroup variant="flush">
                        {(docs.length > 0 ) && docs.map((acc) => {
                            return(
                                <ListGroup.Item>
                                    {(acc.media.contentUrl.includes(".pdf"))?
                                        <><FaFilePdf /> <a href={process.env.REACT_APP_SERVER_URL + acc.media.contentUrl} target='_blank'>Переглянути</a> </>
                                        :
                                        <><FaRegFileAlt /> <a href={process.env.REACT_APP_SERVER_URL + acc.media.contentUrl} download>Завантажити</a> </>
                                    }
                                    <span> {acc.type}</span>
                                    <span> <strong>{acc.name}</strong></span>
                                    <p>{acc.description}</p>
                                </ListGroup.Item>
                            )
                        })}
                        </ListGroup>
                    </Card>
            </Card.Body>
        </Card>
        <hr/>
        <Card>
            <Card.Header as="h5">Повідомлення</Card.Header>
            <Card.Body>
                <Message agreement_id={data.id}/>   
            </Card.Body>
        </Card>
        <hr/>
        <Card>
            <Card.Header as="h5">Історія споживання</Card.Header>
            <Card.Body>
                <HistoryPower agreement_id={data.id}/>   
            </Card.Body>
        </Card>
        
        
    </>);
}

export default AgreementsView;