import React, { useEffect, useState } from 'react';
import { CFormSelect, CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormLabel } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AsyncSelect from 'react-select/async';
import AgreementCalculationModal from 'src/components/agreements/AgreementCalculationModal';
import { AiOutlineEye } from 'react-icons/ai';
import useOpenAccordion from 'src/components/store/useOpenAccordion';
import useCurrency from 'src/components/store/useCurrency';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const page = 1;
const postPerPage = 0;
const pageSize = 20;
const urlApi = '/agreements';
const urlRouter = '/agreements';

const role = localStorage.getItem('user_role');
const user_data = JSON.parse(localStorage.getItem('user_data'));
const user_id = (user_data != null && user_data != undefined && user_data.id != undefined) ? user_data.id : false;
const defaultUserFilter = (role == 'ROLE_MANAGER' && user_id) ? `&account.manager=${user_id}` : '';



export async function LoaderAgreements({ params }) {
  // console.log("params LoaderAgreements", params);
  let res = await AxiosInstance.get(`${urlApi}?page=${page}` + defaultUserFilter).then((response) => {
    // console.log("response LoaderAgreements", response);
    return response;
  });
  return res;
}

const Agreements = ({user_data}) => {
  if(!Permissions(user_data, 'Agreements', 'VIEW_AGREEMENTS')){
    return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  const [accountNameFilterSearch, setAccountNameFilterSearch] = useState('');
  const [typeNameFilterSearch, setTypeNameFilterSearch] = useState('');
  const [userIdFilterSearch, setUserIdFilterSearch] = useState('');
  const [regionFilterSearch, setRegionFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  // Відкрити/закрити вікно з розрахунком по договору
  const [openCalculation, setOpenCalculation] = useState(false);
  const [dataAgreements, setDataAgreements] = useState([]);

  // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
  const activeAccordionItem = useOpenAccordion();
  const activeItem = activeAccordionItem.activeItem;

  // Для відображення назви користувача в полі AsyncSelect
  const getUserName = (user) => {
    let fn = (user.firstName != undefined) ? `${user.firstName} ` : '';
    let ln = (user.lastName != undefined) ? `${user.lastName} ` : '';
    let un = (user.username != undefined) ? ` (${user.username})` : '';
    return `${fn}${ln}${un}`;
  };

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data['hydra:member']);
  };

  // Запит за договорами (+фільтр)
  const getRowsTable = (p = 1) => {
    let request = ``;
    if (nameFilterSearch != '') {
      request += `&number=${nameFilterSearch}`;
    };
    if (regionFilterSearch != '') {
      request += `&region=${regionFilterSearch}`;
    };
    if (typeNameFilterSearch != '') {
      request += `&type=${typeNameFilterSearch}`;
    };
    if (accountNameFilterSearch != '') {
      request += `&account.name=${accountNameFilterSearch}`;
    };
    if (userIdFilterSearch != '') {
      request += `&account.user.id=${userIdFilterSearch}`;
    }
    // console.log("request", request);
    AxiosInstance.get(`${urlApi}?page=${p}${request}` + defaultUserFilter).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  // Для поля Користувач (AsyncSelect)
  const loadOptions = async (inputValue) => {
    return await AxiosInstance.get(`/users?firstName=${inputValue}&lastName=${inputValue}&username=${inputValue}`, { headers: { 'accept': 'application/json' } }).then((response) => {
      return response.data.map((result) => ({
        label: getUserName(result),
        value: result.id,
      }));
    });
  };

  // Для поля Користувач (AsyncSelect)
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setUserIdFilterSearch(selectedOption.value);
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = event => {
    if (event.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    };
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (nameFilterSearch != "" || accountNameFilterSearch != "" || userIdFilterSearch != "") {
      setDisabled(false);
    };
  }, [nameFilterSearch, accountNameFilterSearch, userIdFilterSearch]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = event => {
    setNameFilterSearch(event.target.value);
    disabledButton(event);
  };
  const handleRegionFilterChange = event => {
    setRegionFilterSearch(event.target.value);
    disabledButton(event);
  };
  const handleAccountNameFilterChange = event => {
    setAccountNameFilterSearch(event.target.value);
    disabledButton(event);
  };
  const handleUserIdFilterChange = event => {
    setUserIdFilterSearch(event.target.value);
    disabledButton(event);
  };
  // ----- End handleFilterChange -----

  // Фільтрування
  const handleFilterSearch = () => {
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch("");
    setAccountNameFilterSearch("");
    setUserIdFilterSearch("");
    setSelectedOption(null);
    setDisabled(true);
    getRowsTable();
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name',
      headerName: 'Номер',
      width: 300,
      renderCell: (params) => {
        return <Link to={`/agreements/update/${params.row.id}`} >{params.row.number}</Link>;
      }
    },

    { field: 'type', headerName: 'Тип', width: 150 },
    { field: 'region', headerName: 'Область/Регіон', width: 150 },
    // { field: 'organization', headerName: 'Організація', width: 200 },
    {
      field: 'account.name', headerName: 'Контрагент', width: 300,
      renderCell: (params) => {
        // console.log('params.row', params.row);
        return params.row.account.name;
      }
    },


    {
      field: "action",
      headerName: "Переглянути",
      sortable: false,
      width: 102,
      renderCell: (params) => {
        // console.log("params action", params)
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><AiOutlineEye size={20} /></CButton></Link>;

      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };


  // Функція для закриття модального вікна з розрахунком по договору
  const handleCloseCalculation = () => {
    // Закрити модальне з попередженням
    setOpenCalculation(false);
  };
  return (
    <>
      {/* Модальне вікно з розрахунком по договору */}
      {
        (openCalculation) &&
        <AgreementCalculationModal openCalculation={openCalculation} handleCloseCalculation={handleCloseCalculation} dataAgreements={dataAgreements} />
      }

      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CAccordion key={activeItem} activeItemKey={activeItem} flush>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
                <CAccordionBody>
                  <CRow className="my-3 mx-2">
                    <CCol md={9} className="me-auto">
                      <CRow>
                        <CCol md={2} className='filter-field'>
                          <CFormInput
                            placeholder="Номер" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                            onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                          />
                        </CCol>
                        <CCol md={3} className='filter-field'>
                          <CFormInput
                            placeholder="Контрагент" id="accountNameFilterSearch" aria-label="Пошук" className="me-2"
                            onChange={handleAccountNameFilterChange} value={accountNameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                          />
                        </CCol>
                        <CCol md={2} className='filter-field'>
                          <CFormSelect
                            aria-label="тип"
                            onChange={(e) => {setTypeNameFilterSearch(e.target.value)}}
                            name="type"
                            options={[
                              { label: 'Тендерний', value: 'Тендерний' },
                              { label: 'Не тендерний', value: 'Не тендерний' },
                            ]}
                          />
                        </CCol>
                        <CCol md={3} className='filter-field'>
                          <CFormInput
                            placeholder="Область/Регіон" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                            onChange={handleRegionFilterChange } value={regionFilterSearch} onKeyDown={keyDown} autoComplete="off"
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol md={3} style={{ textAlign: "end" }}>
                      <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                      <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                    </CCol>
                  </CRow>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>

            <CCardHeader>
              <strong>Договори</strong> <small></small>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
              <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height="15" />Створити</CButton></Link>
            </p>
              <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={30}
                  rowsPerPageOptions={[30]}
                  pagination
                  rowCount={rowCountState}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol >
      </CRow >
    </>
  );
};

export default Agreements;
