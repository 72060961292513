import React from 'react';

import News, { LoaderNews } from './views/news/News';
import NewsForm, { LoaderNewsUpdate } from './views/news/NewsForm';

import Banners, { LoaderBanners } from './views/banners/Banners';
import BannersForm, { LoaderBannersUpdate } from './views/banners/BannersForm';

import Pages, { LoaderPages } from './views/site-page/Pages';
import PagesForm, { LoaderPagesUpdate } from './views/site-page/PagesForm';

import Projects, { LoaderProjects } from './views/projects/Projects';
import ProjectsForm, { LoaderProjectsUpdate } from './views/projects/ProjectsForm';

import Blog, { LoaderBlog } from './views/blog/Blog';
import BlogForm, { LoaderBlogUpdate } from './views/blog/BlogForm';

import Menu, { LoaderMenu } from './views/menu/Menu';
import MenuForm, { LoaderMenuUpdate } from './views/menu/MenuForm';

import Media, { LoaderMedia } from './views/media/Media';

import Options, { LoaderOptions } from './views/options/Options';
import OptionForm, { LoaderOptionUpdate } from './views/options/OptionForm';

import CreatedForms, { LoaderCreatedForms } from './views/builder-forms/CreatedForms';
import BuilderForms, { LoaderBuilderForms } from './views/builder-forms/BuilderForms';
import FormsAnswer, { LoaderFormsAnswer } from './views/form-answer/FormsAnswer';
import FormAnswerItem, { LoaderFormAnswerItem } from './views/form-answer/FormAnswerItem';

import Products, { LoaderProducts } from './views/products/Products';
import ProductForm, { LoaderProductsUpdate } from './views/products/ProductForm';
import ProductInformation, { LoaderProductInformation } from './views/products/ProductInformation';

import SiteProducts, { LoaderSiteProducts } from './views/site-products/SiteProducts';
import SiteProductForm, { LoaderSiteProductsUpdate } from './views/site-products/SiteProductForm';

import Category, { LoaderCategory } from './views/category/Category';
import CategoryForm, { LoaderCategoryUpdate } from './views/category/CategoryForm';

import Users, { LoaderUsers } from './views/users/Users';
import UsersForm, { LoaderUsersUpdate } from './views/users/UsersForm';
import UsersView, {LoaderUsersView} from './views/users/UsersView';

import Accounts, { LoaderAccounts } from './views/accounts/Accounts';
import AccountsForm, { LoaderAccountsUpdate } from './views/accounts/AccountsForm';
import AccountsView, {LoaderAccountsView} from './views/accounts/AccountsView';


import MeasurmentUnit, { LoaderMeasurmentUnit } from './views/measurment-unit/MeasurmentUnit';
import MeasurmentUnitForm, { LoaderMeasurmentUnitUpdate } from './views/measurment-unit/MeasurmentUnitForm';

import Storage, { LoaderStorage } from './views/storage/Storage';
import StorageForm, { LoaderStorageUpdate } from './views/storage/StorageForm';

import Orders, { LoaderOrders } from './views/orders/Orders';
import OrdersForm, { LoaderOrdersUpdate } from './views/orders/OrdersForm';
import OrderPrint, { LoaderOrderPrint } from './views/orders/OrderPrint';

import PreOrders, { LoaderPreOrders } from './views/pre_orders/PreOrders';
import PreOrdersForm, { LoaderPreOrdersUpdate } from './views/pre_orders/PreOrdersForm';

import Agreements, { LoaderAgreements } from './views/agreements/agreements';
import AgreementId, { LoaderAgreementId } from './views/agreements/agreementId';
import AgreementsForm, {LoaderAgreementUpdate} from './views/agreements/agreementsForm';
import AgreementsView, {LoaderAgreementsView} from './views/agreements/agreementsView';

import Attributes, { LoaderAttributes } from './views/attributes/attributes';
import AttributesForm, { LoaderAttributesUpdate } from './views/attributes/attributesForm';
import VariationsForm, { LoaderVariationsUpdate } from './views/attributes/variationsForm';
import Home, {LoaderHome} from './views/home/Home';

import Statistics from './views/statistics/Statistics';

import ImportDocs from './views/documents/ImportDocs';


import Faq, { LoaderFaq } from './views/faq/faq';
import FaqForm, { LoaderFaqUpdate } from './views/faq/faqForm';




import Coupons, { LoaderCoupons } from './views/coupons/coupons';
import CouponsForm, { LoaderCoponsUpdate } from './views/coupons/couponsForm';

import Fees, { LoaderFees } from './views/fees/Fees';
import FeesForm, { LoaderFeesUpdate } from './views/fees/FeesForm';

import StorageElement, { LoaderStorageElement } from './views/storage-element/StorageElement';
import StorageElementForm, { LoaderStorageElementUpdate } from './views/storage-element/StorageElementForm';


import Locations, { LoaderLocations } from './views/locations/Locations';
import LocationsForm, { LoaderLocationsUpdate } from './views/locations/LocationsForm';

import StorageBalance, { LoaderStorageBalance } from './views/storage-balance/StorageBalance';

import Prices from './views/products/Prices';

import Slider, { LoaderSlider } from './views/slider/Slider';
import SliderForm, { LoaderSliderUpdate } from './views/slider/SliderForm';

import Objects, { LoaderObjects } from './views/objects/Objects';
import ObjectsForm, { LoaderObjectsUpdate } from './views/objects/ObjectsForm';


import Gallery, { LoaderGallery } from './views/gallery/Gallery';
import GalleryForm, { LoaderGalleryUpdate } from './views/gallery/GalleryForm';


import Roles, { LoaderRoles } from './views/roles/Roles';
import RolesForm, { LoaderRolesUpdate } from './views/roles/RolesForm';

import History , { LoaderHistory } from './views/history/History';



import UsefulMaterials, { LoaderUsefulMaterials } from './views/useful-materials/UsefulMaterials';
import UsefulMaterialForm, { LoaderUsefulMaterialUpdate } from './views/useful-materials/UsefulMaterialForm';
import UsefulMaterialInformation, { LoaderUsefulMaterialInformation } from './views/useful-materials/UsefulMaterialInformation';
import Languages, { LoaderLanguages } from './views/languages/Languages';
import LanguagesForm, { LoaderLanguagesUpdate } from './views/languages/LanguagesForm';
import Specialty, { LoaderSpecialty } from './views/specialty/Specialty';
import SpecialtyForm, { LoaderSpecialtyUpdate } from './views/specialty/SpecialtyForm';
import Events, { LoaderEvents } from './views/events/Events';
import EventsForm, { LoaderEventsUpdate } from './views/events/EventsForm';
import Materials, { LoaderMaterials } from './views/materials/Materials';
import MaterialsForm, { LoaderMaterialsUpdate } from './views/materials/MaterialsForm';
import Subscribers, { LoaderSubscribers } from './views/subscribers/Subscribers';
import SubscribersForm, { LoaderSubscribersUpdate } from './views/subscribers/SubscribersForm';
import MaterialsByLocations from './views/materials/MaterialsByLocations';
import MaterialsBySpecialty from './views/materials/MaterialsBySpecialty';

import Documents, {LoaderDocuments} from './views/documents/Documents';





const Login = React.lazy(() => import('./views/pages/login/Login'))



/** ASD ROUTERS */
// const NewsForm = React.lazy(() => import('./views/news/NewsForm'))

const user = localStorage.getItem('user_data');

const routes = [
  { path: '/', exact: true, name: 'Головна', element: <Home user_data={user} />, loader: LoaderHome },
  { path: '/h', exact: true, name: 'Головна', element: <Home user_data={user} />, loader: LoaderHome },
  { path: '/h/:key', exact: true, name: 'Головна', element: <Home user_data={user} />, loader: LoaderHome },
  /** ASD Routers */

  { path: '/builder-forms', name: 'Форми/Мої форми', element: <CreatedForms user_data={user} />, loader: LoaderCreatedForms },
  { path: '/builder-forms/create', name: 'Створити форму', element: <BuilderForms user_data={user} />, loader: LoaderBuilderForms },
  { path: '/builder-forms/update/:pid', name: 'Редагувати форму', element: <BuilderForms user_data={user} />, loader: LoaderBuilderForms },

  { path: '/form-answer', name: 'Форми/Відповіді', element: <FormsAnswer user_data={user} />, loader: LoaderFormsAnswer },
  { path: '/form-answer/review/:pid', name: 'Відповідь', element: <FormAnswerItem user_data={user} />, loader: LoaderFormAnswerItem },

  { path: '/news', name: 'Новини', element: <News user_data={user} />, loader: LoaderNews },
  { path: '/news/create', name: 'Створити новини', element: <NewsForm user_data={user} />, loader: LoaderNewsUpdate },
  { path: '/news/update/:newsId', name: 'Редагувати новини', element: <NewsForm user_data={user} />, loader: LoaderNewsUpdate },

  { path: '/banners', name: 'Банери', element: <Banners user_data={user} />, loader: LoaderBanners },
  { path: '/banners/create', name: 'Створити банер', element: <BannersForm user_data={user} />, loader: LoaderBannersUpdate },
  { path: '/banners/update/:newsId', name: 'Редагувати банер', element: <BannersForm user_data={user} />, loader: LoaderBannersUpdate },

  { path: '/site-pages', name: 'Сторінки', element: <Pages user_data={user} />, loader: LoaderPages },
  { path: '/site-pages/create', name: 'Створити сторінку', element: <PagesForm user_data={user} />, loader: LoaderPagesUpdate },
  { path: '/site-pages/update/:id', name: 'Редагувати сторінку', element: <PagesForm user_data={user} />, loader: LoaderPagesUpdate },

  { path: '/projects', name: 'Проекти', element: <Projects user_data={user} />, loader: LoaderProjects },
  { path: '/projects/create', name: 'Створити проект', element: <ProjectsForm user_data={user} />, loader: LoaderProjectsUpdate },
  { path: '/projects/update/:prodId', name: 'Редагувати проект', element: <ProjectsForm user_data={user} />, loader: LoaderProjectsUpdate },

  { path: '/post', name: 'Пости', element: <Blog user_data={user} />, loader: LoaderBlog },
  { path: '/post/create', name: 'Створити пост', element: <BlogForm user_data={user} />, loader: LoaderBlogUpdate },
  { path: '/post/update/:newsId', name: 'Редагувати пост', element: <BlogForm user_data={user} />, loader: LoaderBlogUpdate },

  { path: '/menu', name: 'Меню', element: <Menu user_data={user} />, loader: LoaderMenu },
  { path: '/menu/create', name: 'Створити меню', element: <MenuForm user_data={user} />, loader: LoaderMenuUpdate },
  { path: '/menu/update/:menuId', name: 'Редагувати меню', element: <MenuForm user_data={user} />, loader: LoaderMenuUpdate },
  { path: '/menu/children/:child_id', name: 'Редагувати меню', element: <MenuForm user_data={user} />, loader: LoaderMenuUpdate },

  { path: '/media', name: 'Медіа', element: <Media user_data={user} />, loader: LoaderMedia },

  { path: '/options', name: 'Налаштування', element: <Options user_data={user} />, loader: LoaderOptions },
  { path: '/options/create', name: 'Створити Налаштування', element: <OptionForm user_data={user} />, loader: LoaderOptionUpdate },
  { path: '/options/update/:optionId', name: 'Редагувати Налаштування', element: <OptionForm user_data={user} />, loader: LoaderOptionUpdate },

  { path: '/login', name: 'Логін', element: <Login /> },

  { path: '/products', name: 'Товари', element: <Products user_data={user} />, loader: LoaderProducts },
  { path: '/products/create', name: 'Створити Товари', element: <ProductForm user_data={user} />, loader: LoaderProductsUpdate },
  { path: '/products/update/:pid', name: 'Редагувати Товари', element: <ProductForm user_data={user} />, loader: LoaderProductsUpdate },
  { path: '/products/read/:pid', name: 'Перегляд товару', element: <ProductInformation user_data={user} />, loader: LoaderProductInformation },
  { path: '/products/prices', name: 'Список товарів з цінами', element: <Prices user_data={user} /> },
  { path: '/products/update/:pid/:lang', name: 'Редагувати Товари', element: <ProductForm user_data={user} />, loader: LoaderProductsUpdate },

  { path: '/site-products', name: 'Товари на сайті', element: <SiteProducts user_data={user} />, loader: LoaderSiteProducts },
  { path: '/site-products/create', name: 'Створити Товар на сайті', element: <SiteProductForm user_data={user} />, loader: LoaderSiteProductsUpdate },
  { path: '/site-products/update/:pid', name: 'Редагувати Товар на сайті', element: <SiteProductForm user_data={user} />, loader: LoaderSiteProductsUpdate },

  { path: '/category', name: 'Категорії', element: <Category user_data={user} />, loader: LoaderCategory },
  { path: '/category/create', name: 'Створити категорію', element: <CategoryForm user_data={user} />, loader: LoaderCategoryUpdate },
  { path: '/category/update/:pid', name: 'Редагувати Категорію', element: <CategoryForm user_data={user} />, loader: LoaderCategoryUpdate },

  { path: '/users', name: 'Користувачі', element: <Users user_data={user} />, loader: LoaderUsers },
  { path: '/users/create', name: 'Створити користувача', element: <UsersForm user_data={user} />, loader: LoaderUsersUpdate },
  { path: '/users/update/:pid', name: 'Редагувати користувача', element: <UsersForm user_data={user} />, loader: LoaderUsersUpdate },
  { path: '/users/:pid', name: 'Редагувати користувача', element: <UsersView />, loader: LoaderUsersView },

  { path: '/accounts', name: 'Контрагенти', element: <Accounts user_data={user} />, loader: LoaderAccounts },
  { path: '/accounts/create', name: 'Створити контрагента', element: <AccountsForm user_data={user} />, loader: LoaderAccountsUpdate },
  { path: '/accounts/update/:pid', name: 'Редагувати контрагента', element: <AccountsForm user_data={user} />, loader: LoaderAccountsUpdate },
  { path: '/accounts/:pid', name: 'Перегляд контрагента', element: <AccountsView />, loader: LoaderAccountsView },

  { path: '/measurment-unit', name: 'Одиниці виміру', element: <MeasurmentUnit user_data={user} />, loader: LoaderMeasurmentUnit },
  { path: '/measurment-unit/create', name: 'Створити Одиниці виміру', element: <MeasurmentUnitForm user_data={user} />, loader: LoaderMeasurmentUnitUpdate },
  { path: '/measurment-unit/update/:pid', name: 'Редагувати Одиниці виміру', element: <MeasurmentUnitForm user_data={user} />, loader: LoaderMeasurmentUnitUpdate },

  { path: '/storage', name: 'Склади', element: <Storage user_data={user} />, loader: LoaderStorage },
  { path: '/storage/create', name: 'Створити Склад', element: <StorageForm user_data={user} />, loader: LoaderStorageUpdate },
  { path: '/storage/update/:pid', name: 'Редагувати Склад', element: <StorageForm user_data={user} />, loader: LoaderStorageUpdate },

  { path: '/orders', name: 'Розхідна накладна', element: <Orders user_data={user} />, loader: LoaderOrders },
  { path: '/orders/create', name: 'Створити розхідну накладну', element: <OrdersForm user_data={user} />, loader: LoaderOrdersUpdate },
  { path: '/orders/update/:pid', name: 'Редагувати розхідну накладну', element: <OrdersForm user_data={user} />, loader: LoaderOrdersUpdate },
  { path: '/orders/print/:id', name: 'Друкувати', element: <OrderPrint user_data={user} />, loader: LoaderOrderPrint },

  { path: '/pre-orders', name: 'Онлайн Замовлення', element: <PreOrders user_data={user} />, loader: LoaderPreOrders },
  { path: '/pre-orders/create', name: 'Створити онлайн замовлення', element: <PreOrdersForm user_data={user} />, loader: LoaderPreOrdersUpdate },
  { path: '/pre-orders/update/:pid', name: 'Редагувати онлайн замовлення', element: <PreOrdersForm user_data={user} />, loader: LoaderPreOrdersUpdate },

  { path: '/agreements', name: 'Договори', element: <Agreements user_data={user} />, loader: LoaderAgreements },
  { path: '/agreements/:pid', name: 'Договори ', element: <AgreementsView user_data={user} />, loader: LoaderAgreementsView },
  { path: '/agreements/create', name: 'Створити Договори', element: <AgreementsForm user_data={user} />, loader: LoaderAgreementUpdate },
  { path: '/agreements/update/:pid', name: 'Створити Договори', element: <AgreementsForm user_data={user} />, loader: LoaderAgreementUpdate },

  { path: '/attributes', name: 'Атрибути', element: <Attributes user_data={user} />, loader: LoaderAttributes },
  { path: '/attributes/create', name: 'Створити атрибут', element: <AttributesForm user_data={user} />, loader: LoaderAttributesUpdate },
  { path: '/attributes/update/:pid', name: 'Редагувати атрибут', element: <AttributesForm user_data={user} />, loader: LoaderAttributesUpdate },
  { path: '/attributes/variations/update/:pid', name: 'Редагувати варіацію', element: <VariationsForm user_data={user} />, loader: LoaderVariationsUpdate },


  { path: '/statistics', name: 'Статистика', element: <Statistics user_data={user} /> },


  { path: '/coupons', name: 'Купони', element: <Coupons user_data={user} />, loader: LoaderCoupons },
  { path: '/coupons/create', name: 'Створити купон', element: <CouponsForm user_data={user} />, loader: LoaderCoponsUpdate },
  { path: '/coupons/update/:pid', name: 'Редагувати купон', element: <CouponsForm user_data={user} />, loader: LoaderCoponsUpdate },

  { path: '/fees', name: 'Збори', element: <Fees user_data={user} />, loader: LoaderFees },
  { path: '/fees/create', name: 'Створити збори', element: <FeesForm user_data={user} />, loader: LoaderFeesUpdate },
  { path: '/fees/update/:pid', name: 'Редагувати збори', element: <FeesForm user_data={user} />, loader: LoaderFeesUpdate },


  { path: '/storage-element', name: 'Елемент складу', element: <StorageElement user_data={user} />, loader: LoaderStorageElement },
  { path: '/storage-element/create', name: 'Елемент складу', element: <StorageElementForm user_data={user} />, loader: LoaderStorageElementUpdate },
  { path: '/storage-element/update/:pid', name: 'Елемент складу', element: <StorageElementForm user_data={user} />, loader: LoaderStorageElementUpdate },

  { path: '/locations', name: 'Підрозділи', element: <Locations user_data={user} />, loader: LoaderLocations },
  { path: '/locations/create', name: 'Створити підрозділ', element: <LocationsForm user_data={user} />, loader: LoaderLocationsUpdate },
  { path: '/locations/update/:pid', name: 'Оновити підрозділ', element: <LocationsForm user_data={user} />, loader: LoaderLocationsUpdate },

  { path: '/stage-balance', name: 'Залишки', element: <StorageBalance user_data={user} />, loader: LoaderStorageBalance },
  { path: '/stage-balance/:pid', name: 'Залишки', element: <StorageBalance user_data={user} />, loader: LoaderStorageBalance },
  { path: '/stage-balance', name: 'Залишки', element: <StorageBalance />, loader: LoaderStorageBalance },

  { path: '/builder-forms', name: 'Форми/Мої форми', element: <CreatedForms user_data={user} />, loader: LoaderCreatedForms },
  { path: '/builder-forms/create', name: 'Створити форму', element: <BuilderForms user_data={user} />, loader: LoaderBuilderForms },
  { path: '/builder-forms/update/:pid', name: 'Редагувати форму', element: <BuilderForms user_data={user} />, loader: LoaderBuilderForms },

  { path: '/form-answer', name: 'Форми/Відповіді', element: <FormsAnswer user_data={user} />, loader: LoaderFormsAnswer },
  { path: '/form-answer/review/:pid', name: 'Відповідь', element: <FormAnswerItem user_data={user}  />, loader: LoaderFormAnswerItem },

  {
    path: '/useful-materials', name: 'Корисні матеріали', element: <UsefulMaterials user_data={user} />
    , loader: LoaderUsefulMaterials
  },
  { path: '/useful-materials/create', name: 'Створити корисний матеріал', element: <UsefulMaterialForm user_data={user} />, loader: LoaderUsefulMaterialUpdate },
  { path: '/useful-materials/update/:pid', name: 'Редагувати корисний матеріал', element: <UsefulMaterialForm user_data={user} />, loader: LoaderUsefulMaterialUpdate },
  { path: '/useful-materials/read/:pid', name: 'Перегляд корисного матеріалу', element: <UsefulMaterialInformation user_data={user} />, loader: LoaderUsefulMaterialInformation },



  { path: '/slider', name: 'Слайдер', element: <Slider user_data={user} />, loader: LoaderSlider },
  { path: '/slider/create', name: 'Створити слайдер', element: <SliderForm user_data={user} />, loader: LoaderSliderUpdate },
  { path: '/slider/update/:menuId', name: 'Редагувати слайдер', element: <SliderForm user_data={user} />, loader: LoaderSliderUpdate },
  { path: '/slider/children/:child_id', name: 'Редагувати слайдер', element: <SliderForm user_data={user} />, loader: LoaderSliderUpdate },

  { path: '/objects', name: 'Обєкти', element: <Objects user_data={user} />, loader: LoaderObjects },
  { path: '/objects/create', name: 'Створити Обєкт', element: <ObjectsForm user_data={user} />, loader: LoaderObjectsUpdate },
  { path: '/objects/update/:id', name: 'Редагувати Обєкт', element: <ObjectsForm user_data={user} />, loader: LoaderObjectsUpdate },

  { path: '/gallery', name: 'Галерея', element: <Gallery user_data={user} />, loader: LoaderGallery },
  { path: '/gallery/create', name: 'Створити галерею', element: <GalleryForm user_data={user} />, loader: LoaderGalleryUpdate },
  { path: '/gallery/update/:id', name: 'Редагувати галерею', element: <GalleryForm user_data={user} />, loader: LoaderGalleryUpdate },



  { path: '/roles', name: 'Ролі', element: <Roles user_data={user} />, loader: LoaderRoles },
  { path: '/roles/create', name: 'Створити роль', element: <RolesForm user_data={user} />, loader: LoaderRolesUpdate },
  { path: '/roles/update/:id', name: 'Редагувати роль', element: <RolesForm user_data={user} />, loader: LoaderRolesUpdate },

  { path: '/languages', name: 'Мови', element: <Languages user_data={user} />, loader: LoaderLanguages },
  { path: '/languages/create', name: 'Створити мову', element: <LanguagesForm user_data={user} />, loader: LoaderLanguagesUpdate },
  { path: '/languages/update/:pid', name: 'Редагувати мову', element: <LanguagesForm user_data={user} />, loader: LoaderLanguagesUpdate },
  { path: '/faq', name: 'FAQ', element: <Faq user_data={user} />, loader: LoaderFaq },
  { path: '/faq/create', name: 'Створити FAQ', element: <FaqForm user_data={user} />, loader: LoaderFaqUpdate },
  { path: '/faq/update/:pid', name: 'Редагувати FAQ', element: <FaqForm user_data={user} />, loader: LoaderFaqUpdate },


  { path: '/p/:type', name: 'Сторінки', element: <Pages user_data={user} />, loader: LoaderPages },
  { path: '/p/:type/create', name: 'Створити сторінку', element: <PagesForm user_data={user} />, loader: LoaderPagesUpdate },
  { path: '/p/:type/:id', name: 'Редагувати сторінку', element: <PagesForm user_data={user} />, loader: LoaderPagesUpdate },

  { path: '/specialty', name: 'Спеціальності', element: <Specialty user_data={user} />, loader: LoaderSpecialty },
  { path: '/specialty/create', name: 'Створити спеціальність', element: <SpecialtyForm user_data={user} />, loader: LoaderSpecialtyUpdate },
  { path: '/specialty/update/:pid', name: 'Оновити спеціальність', element: <SpecialtyForm user_data={user} />, loader: LoaderSpecialtyUpdate },

  { path: '/events', name: 'Події', element: <Events user_data={user} />, loader: LoaderEvents },
  { path: '/events/create', name: 'Створити подію', element: <EventsForm user_data={user} />, loader: LoaderEventsUpdate },
  { path: '/events/update/:pid', name: 'Оновити подію', element: <EventsForm user_data={user} />, loader: LoaderEventsUpdate },

  { path: '/materials', name: 'Матеріали', element: <Materials user_data={user} />, loader: LoaderMaterials },
  { path: '/materials/create', name: 'Створити матеріал', element: <MaterialsForm user_data={user} />, loader: LoaderMaterialsUpdate },
  { path: '/materials/update/:pid', name: 'Оновити матеріал', element: <MaterialsForm user_data={user} />, loader: LoaderMaterialsUpdate },

  { path: '/post/:type', name: 'Сторінки', element: <Blog user_data={user} />, loader: LoaderBlog },
  { path: '/post/:type/create', name: 'Створити сторінку', element: <BlogForm user_data={user} />, loader: LoaderBlogUpdate },
  { path: '/post/:type/:id', name: 'Редагувати сторінку', element: <BlogForm user_data={user} />, loader: LoaderBlogUpdate },

  { path: '/materials/materials-by-locations', name: 'Матеріали - перегляд за підрозділом', element: <MaterialsByLocations user_data={user} /> },

  { path: '/materials/materials-by-specialty', name: 'Матеріали - перегляд за напрямком', element: <MaterialsBySpecialty user_data={user} /> },

  { path: '/subscribers', name: 'Учасники', element: <Subscribers user_data={user} />, loader: LoaderSubscribers },
  { path: '/subscribers/create', name: 'Створити учасника', element: <SubscribersForm user_data={user} />, loader: LoaderSubscribersUpdate },
  { path: '/subscribers/update/:pid', name: 'Редагувати учасника', element: <SubscribersForm user_data={user} />, loader: LoaderSubscribersUpdate },

  { path: '/documents', name: 'Документи', element: <Documents user_data={user} />, loader: LoaderDocuments },
  
  { path: '/history', name: 'Історія', element: <History user_data={user} />, loader: LoaderHistory },
  { path: '/import-docs', name: 'Імпортувати документи', element: <ImportDocs user_data={user} /> },
]

export default routes
