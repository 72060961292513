import PermissionsNoPage, {Permissions} from "src/components/Permissions";




const ImportDocs = ({user_data}) => {
    if(!Permissions(user_data, 'Documents', 'VIEW_DOCUMENTS')){
        return <PermissionsNoPage />;
    }


    return (<>
        <h1>Завантаження даних!</h1>
        <iframe src={process.env.REACT_APP_SERVER_URL + '/import-docs?iframe=100Inn'} width={800} height={500}></iframe>
    </>)
}


export default ImportDocs;