import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import useOpenAccordion from 'src/components/store/useOpenAccordion';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";
import BasicModal from 'src/components/Modal';


const page = 1;
const postPerPage = 0;
const pageSize = 100;
const urlApi = '/histories';
const urlRouter = '/history';

const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));

export async function LoaderHistory({ params }) {
  let query = `page=${page}`;

  let res = await AxiosInstance.get(`${urlApi}?${query}`).then((response) => {
    return response;
  });
  return res;
}

const History = ({user_data}) => {
  if(!Permissions(user_data, 'History', 'VIEW_HISTORY')){
    return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const [methodSearch, setMethodSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);

  // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
  const activeAccordionItem = useOpenAccordion();
  const activeItem = activeAccordionItem.activeItem;

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data['hydra:member']);
  };

  // Запит за контрагентами (+фільтр)
  const getRowsTable = (p = 1) => {
    let request = ``;
    if (nameFilterSearch != '') {
      request += `&name=${nameFilterSearch}`;
    };
    if (userSearch != '') {
      request += `&owner.username=${userSearch}`;
    };
    if (methodSearch != '') {
      request += `&method=${methodSearch}`;
    };
    // if (role == 'ROLE_MANAGER')
    //   request += `&manager.id=${userData.id}`;

    // console.log("request", request);
    AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = event => {
    if (event.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    };
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (nameFilterSearch != "" || userSearch != "" || methodSearch !== "") {
      setDisabled(false);
    };
  }, [nameFilterSearch, userSearch, methodSearch]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = event => {
    setNameFilterSearch(event.target.value);
    disabledButton(event);
  };
  const handleManagerFirstNameFilterChange = event => {
    setUserSearch(event.target.value);
    disabledButton(event);
  };
  const handleEdrpouChange = event => {
    setMethodSearch(event.target.value);
    disabledButton(event);
  };
  // ----- End handleFilterChange -----

  // Фільтрування
  const handleFilterSearch = () => {
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch("");
    setUserSearch("");
    setMethodSearch("");
    setDisabled(true);
    getRowsTable();
  };

  const [showModal, setShowModal] = useState(false);
  // Текст для popup
  const actionShowModal = (rez) => {
      setShowModal(rez);
      
  };


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name', headerName: 'Назва', width: 200,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }} title="Редагувати контрагента">{params.row.name}</Link>;
      }
    },
    {
      field: "owner",
      headerName: "Користувач",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (params.row.owner != undefined) ? `${params.row.owner.username}` : '';
      }
    },
    { field: 'date', headerName: 'Дата', width: 100 },
    { field: 'method', headerName: 'Метод', width: 100 },
    { field: 'description', headerName: 'Опис', width: 400 },
    // { field: 'type', headerName: 'Тип', width: 100 },
    // { field: 'edrpou', headerName: 'ЄДРПОУ', width: 100 },
    // { field: 'maxBorg', headerName: 'Макс. Борг', width: 100 },
    // { field: 'dayDelay', headerName: 'Днів від терм.', width: 150 },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return <>
                  <BasicModal
                        show={showModal}
                        actionShowModal={actionShowModal}
                        title={`Історія`}
                        btn_name={"Деталі"}
                        content={
                           <>
                              <p>{params.row.name}</p>
                              <p>{params.row.date}</p>
                              <p>{params.row.method}</p>
                              <p>{(params.row.owner != undefined) && params.row.owner.username}</p>
                              <p>{params.row.description}</p>
                           </>
                        }
                    />
        </>;
      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion key={activeItem} activeItemKey={activeItem} flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="Назва модуля" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="Логін користувача" id="managerFirstNameSearch" aria-label="Пошук" className="me-2"
                          onChange={handleManagerFirstNameFilterChange} value={userSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="метод" id="edrpouSearch" aria-label="Пошук" className="me-2"
                          onChange={handleEdrpouChange} value={methodSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardHeader>
            <strong>Історія змін</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <div style={{ height: 600, width: '100%' }}>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default History;