import { useEffect, useState } from "react";
import AxiosInstance from "../Axios";
import Table from 'react-bootstrap/Table';
import dayjs from 'dayjs';



const HistoryPower = ({account_id, agreement_id, user_id}) => {

    const [rowsMessage, setRowsMessage] = useState([]);


    // Запит за контрагентами (+фільтр)
    const getRowsMessage = (p = 1) => {
        let request = {};
        if(account_id != undefined)
            request['agreement.account.id'] = account_id;
        if(agreement_id != undefined)
            request['agreement.id'] = agreement_id;
        if(user_id != undefined)
            request['agreement.account.user.id'] = user_id;
        // console.log("request", request);
        AxiosInstance.get(`/history_powers?page=${p}`, {params: request}).then((response) => {
            setRowsMessage(response.data['hydra:member']);
        });
    };
    useEffect(() => {
        getRowsMessage()
    }, [])    
    return(
        <>
             <Table striped>
                <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Споживання, кВт*год</th>
                        <th>Вартість</th>
                        <th>Вартість з ПДВ</th>
                        <th>Номер Договору/Контрагент</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rowsMessage.map((m) => {


                            return(
                                <tr key={`hiss_${m.id}`}>
                                    <th>{dayjs(m.date).format('DD.MM.YYYY')}</th>
                                    <td>{m.count}</td>
                                    <td>{m.sum}</td>
                                    <td>{m.sumPdv}</td>
                                    <td>{m.agreement.number}</td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
             </Table>
            
        </>
    );
}

export default HistoryPower;