import Card from 'react-bootstrap/Card';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormSelect } from '@coreui/react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";
import { useState } from 'react';





const urlRouter = '/accounts';
const urlApi = '/accounts';

export async function LoaderAccountsView({ params }) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
        return response;
    });
    return res;
};


const AccountsView = ({user_data}) => {
    const { data } = useLoaderData();
    const [permissionEdit, SetPermissionEdit] = useState((Permissions(user_data, 'Accounts', 'EDIT_ACCOUNTS'))?true:false) ;

    return (<>
 
        <Card>
            <Card.Header>
                <div className="row">
                    <div className="col-10">
                        <h2>{data.name}</h2>
                    </div>
                    
                    <div className="col-2 d-flex justify-content-end"> 
                        {(permissionEdit) &&
                            <Link to={`${urlRouter}/update/${data.id}`}><CButton color="dark" variant="outline" title="Редагувати контрагента">Редагувати <CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>
                        }
                    </div>
                </div>

            </Card.Header>
            <Card.Body>
                <Table striped>
                    <tbody>
                        {(data.type != undefined) && <tr> <th>Тип</th> <td>{data.type}</td> </tr> }
                        {( data.ipn != undefined) && <tr> <th>ІПН</th> <td>{data.ipn}</td> </tr> }
                        {( data.edrpou != undefined) && <tr> <th>ЄДРПОУ</th> <td>{data.edrpou}</td> </tr> }
                        {( data.address != undefined) && <tr> <th>Адреса</th> <td>{data.address}</td> </tr> }
                        {( data.phone != undefined) && <tr> <th>Телефон</th> <td>{data.phone}</td> </tr> }
                        {( data.description != undefined) && <tr> <th>Коментар</th> <td>{data.description}</td> </tr> }
                        {( data.user != undefined) && <tr> <th>Користувач</th> <td>{data.user.username} - {data.user.firstName}</td> </tr> }
                    </tbody>
                </Table>
                
            </Card.Body>
        </Card>
        <hr/>

        <Card>
            <Card.Header as="h5">Договори</Card.Header>
            <Card.Body className='row'>
                {(data.agreements.length == 0 )&& <p>Договорів не знайдено!</p>}
                {(data.agreements.length > 0 ) && data.agreements.map((acc) => {
                    return(
                        <Link to={`/agreements/${acc.id}`} className='col-3'  style={{textDecoration: 'none'}}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Card.Title>{acc.name} {acc.number}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                    )
                })}
            </Card.Body>
        </Card>
        
        
    </>);
}

export default AccountsView;